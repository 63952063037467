"use client";

import * as React from 'react';
import {
  CommandDialog,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
} from "@/components/ui/command";
import { DialogTitle } from "@/components/ui/dialog";
import { useRouter } from 'next/navigation';
import { CalendarDays, Loader2 } from "lucide-react";

export function CommandMenu({ open, onOpenChange }) {
  const router = useRouter();
  const [searchResults, setSearchResults] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [recentSearches, setRecentSearches] = React.useState([]);
  const searchTimeout = React.useRef();

  React.useEffect(() => {
    // Load recent searches from localStorage
    const saved = localStorage.getItem('recentSearches');
    if (saved) {
      setRecentSearches(JSON.parse(saved));
    }
  }, []);

  const saveRecentSearch = (result) => {
    const newRecent = [
      { title: result.title, slug: result.slug },
      ...recentSearches.filter(item => item.slug !== result.slug),
    ].slice(0, 5);
    
    setRecentSearches(newRecent);
    localStorage.setItem('recentSearches', JSON.stringify(newRecent));
  };

  const handleSearch = React.useCallback(async (value) => {
    // Clear existing timeout
    if (searchTimeout.current) {
      clearTimeout(searchTimeout.current);
    }

    if (!value) {
      setSearchResults([]);
      setIsLoading(false);
      return;
    }

    setIsLoading(true);

    // Debounce the search
    searchTimeout.current = setTimeout(async () => {
      try {
        const response = await fetch(`/api/search?q=${encodeURIComponent(value)}`);
        const data = await response.json();
        
        if (!response.ok) throw new Error(data.error);
        
        setSearchResults(data.results);
      } catch (error) {
        console.error('Search failed:', error);
        setSearchResults([]);
      } finally {
        setIsLoading(false);
      }
    }, 300);

    return () => {
      if (searchTimeout.current) {
        clearTimeout(searchTimeout.current);
      }
    };
  }, []);

  const handleSelect = (result) => {
    saveRecentSearch(result);
    router.push(`/blog/${result.slug}`);
    onOpenChange(false);
  };

  React.useEffect(() => {
    const down = (e) => {
      if (e.key === "k" && (e.metaKey || e.ctrlKey)) {
        e.preventDefault();
        onOpenChange(true);
      }
    };

    document.addEventListener("keydown", down);
    return () => document.removeEventListener("keydown", down);
  }, [onOpenChange]);

  return (
    <CommandDialog open={open} onOpenChange={onOpenChange}>
      <DialogTitle className="sr-only">Search blog posts</DialogTitle>
      <CommandInput
        placeholder="Search posts..."
        onValueChange={handleSearch}
      />
      <CommandList>
        <CommandEmpty>
          {isLoading ? (
            <div className="flex items-center justify-center py-4">
              <Loader2 className="h-4 w-4 animate-spin" />
              <span className="ml-2">Searching...</span>
            </div>
          ) : (
            "No results found."
          )}
        </CommandEmpty>

        {searchResults.length > 0 && (
          <CommandGroup heading="Search Results">
            {searchResults.map((post) => (
              <CommandItem
                key={post.slug}
                onSelect={() => handleSelect(post)}
                className="flex items-center justify-between"
              >
                <span>{post.title}</span>
                {post.date && (
                  <span className="text-sm text-muted-foreground flex items-center">
                    <CalendarDays className="mr-2 h-4 w-4" />
                    {new Date(post.date).toLocaleDateString()}
                  </span>
                )}
              </CommandItem>
            ))}
          </CommandGroup>
        )}

        {recentSearches.length > 0 && (
          <>
            <CommandSeparator />
            <CommandGroup heading="Recent Searches">
              {recentSearches.map((item) => (
                <CommandItem
                  key={item.slug}
                  onSelect={() => handleSelect(item)}
                >
                  {item.title}
                </CommandItem>
              ))}
            </CommandGroup>
          </>
        )}
      </CommandList>
    </CommandDialog>
  );
}